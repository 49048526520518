import RegisterDevice from './RegisterDevice.svelte';

const userId = document.getElementById('notification-component').dataset.userId;

const app = new RegisterDevice({
  target: document.getElementById('notification-component'),
  props: {
    userId: userId
  }
});

export default app;