<script>
  import { onMount } from 'svelte';
  import { requestPermission, onMessageListener } from './firebase';

  onMount(async () => {
      const token = await requestPermission();
      if (token) {
        document.cookie = `X-Device-ID=${token};path=/`;
        const userId = document.getElementById('notification-component').dataset.userId;

          // Send token to server for registration
          fetch('/register-device-api/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ registration_id: token, user_id: userId !== "0" ? userId : null }),
          }).then(response => response.json())
            .then(data => console.log(data));
      }
  });

  onMessageListener().then(payload => {
      console.log('Message received. ', payload);
      // Update UI based on message content
  });
</script>

<main>
  <input type="hidden" id="X-Device-ID"/>
</main>
